export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let id = param.get("id");
  let sub_id_1 = param.get("sub_id_1");
  let cost = param.get("cost");

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }
  if (!id) {
    id = localStorage.getItem("id");
  }
  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }
  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    if (offer === "sSpnP3v7") {
      window.location.href = `https://arumo.top/sSpnP3v7?ad_campaign_id=${ad_campaign_id}`;
    } else if (offer === "Qw4YPHMB") {
      window.location.href =
        `https://arumo.top/Qw4YPHMB?ad_campaign_id=${ad_campaign_id}` +
        (creative_id !== null ? `&creative_id=${creative_id}` : "") +
        (source !== null ? `&source=${source}` : "");
    } else if (id === "1433") {
      window.location.href = `https://arumo.top/YRb8mP81?external_id=${external_id}&creative_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1434") {
      window.location.href = `https://arumo.top/KTSS46QT?ad_campaign_id=${ad_campaign_id}`;
    } else if (id === "1436") {
      window.location.href = `https://arumo.top/QZyCyBkf?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1437") {
      window.location.href = `https://arumo.top/mYL2Ft1w?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1435") {
      window.location.href = `https://arumo.top/pq5Rb12L?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
    } else if (id === "1481") {
      window.location.href = `https://arumo.top/QqqRGV3C?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
    } else if (id === "1487") {
      window.location.href = `https://arumo.top/CN1NQ9?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1488") {
      window.location.href = `https://arumo.top/s7zqp29Z?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1484") {
      window.location.href = `https://arumo.top/Y5WyCffL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else if (id === "1483") {
      window.location.href = `https://arumo.top/2yc59j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
    } else {
      window.location.href =
        `https://arumo.top/g8XSqwqq?ad_campaign_id=${ad_campaign_id}` +
        (creo_id !== null ? `&creative_id=${creo_id}` : "") +
        (external_id !== null ? `&external_id=${external_id}` : "") +
        (site_id !== null ? `&site_id=${site_id}` : "");
    }
  }, 200);

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 12000);

  return <div></div>;
};
