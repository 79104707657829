import { useState } from "react";
import "./App.css";
import { Installed } from "./components/Installed/Installed";
import { Main } from "./components/Main/Main";
import { useReactPWAInstall } from "react-pwa-install";

function App() {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [animating, setAnimating] = useState(false);
  const [megabyte, setMegabyte] = useState(0);

  const param = new URLSearchParams(document.location.search);
  let external_id = param.get("external_id");
  let creative_id = param.get("creative_id");
  let creo_id = param.get("creo_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let source = param.get("source");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");

  let offer = param.get("offer");
  let id = param.get("id");

  if (creo_id) {
    localStorage.setItem("creo_id", creo_id);
  } else {
    creo_id = localStorage.getItem("creo_id");
  }
  if (id) {
    localStorage.setItem("id", id);
  } else {
    id = localStorage.getItem("id");
  }
  if (external_id) {
    localStorage.setItem("external_id", external_id);
  } else {
    external_id = localStorage.getItem("external_id");
  }
  if (site_id) {
    localStorage.setItem("site_id", site_id);
  } else {
    site_id = localStorage.getItem("site_id");
  }
  if (creative_id) {
    localStorage.setItem("creative_id", creative_id);
  } else {
    creative_id = localStorage.getItem("creative_id");
  }
  if (cost) {
    localStorage.setItem("cost", cost);
  } else {
    cost = localStorage.getItem("cost");
  }
  if (ad_campaign_id) {
    localStorage.setItem("ad_campaign_id", ad_campaign_id);
  } else {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }
  if (sub_id_1) {
    localStorage.setItem("sub_id_1", sub_id_1);
  } else {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }
  if (source) {
    localStorage.setItem("source", source);
  } else {
    source = localStorage.getItem("source");
  }

  if (offer) {
    localStorage.setItem("offer", offer);
  } else {
    offer = localStorage.getItem("offer");
  }

  const animationMegabyte = () => {
    setTimeout(() => {
      setMegabyte(0.82);
    }, 1500);

    setTimeout(() => {
      setMegabyte(1.27);
    }, 3000);

    setTimeout(() => {
      setMegabyte(1.84);
    }, 4500);

    setTimeout(() => {
      setMegabyte(2.41);
    }, 6000);

    setTimeout(() => {
      setMegabyte(2.93);
    }, 7500);

    setTimeout(() => {
      setMegabyte(3.4);
    }, 9000);

    setTimeout(() => {
      setMegabyte(3.7);
    }, 10500);

    setTimeout(() => {
      setMegabyte("Installing...");
    }, 12000);
  };

  const handleClick = () => {
    const userAgent = window.navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      if (offer === "sSpnP3v7") {
        window.location.href = `https://arumo.top/sSpnP3v7?ad_campaign_id=${ad_campaign_id}`;
      } else if (offer === "Qw4YPHMB") {
        window.location.href =
          `https://arumo.top/Qw4YPHMB?ad_campaign_id=${ad_campaign_id}` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1433") {
        window.location.href = `https://arumo.top/YRb8mP81?external_id=${external_id}&creative_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1434") {
        window.location.href = `https://arumo.top/KTSS46QT?ad_campaign_id=${ad_campaign_id}`;
      } else if (id === "1436") {
        window.location.href = `https://arumo.top/QZyCyBkf?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1437") {
        window.location.href = `https://arumo.top/mYL2Ft1w?cost=${cost}&external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1435") {
        window.location.href = `https://arumo.top/pq5Rb12L?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1481") {
        window.location.href = `https://arumo.top/QqqRGV3C?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
      } else if (id === "1487") {
        window.location.href = `https://arumo.top/CN1NQ9?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1488") {
        window.location.href = `https://arumo.top/s7zqp29Z?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1484") {
        window.location.href = `https://arumo.top/Y5WyCffL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1483") {
        window.location.href = `https://arumo.top/2yc59j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else {
        window.location.href =
          `https://arumo.top/g8XSqwqq?ad_campaign_id=${ad_campaign_id}` +
          (creo_id !== null ? `&creative_id=${creo_id}` : "") +
          (external_id !== null ? `&external_id=${external_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    } else {
      pwaInstall({
        title: "Install Web App",
      })
        .then(() => {
          setAnimating(true);
          animationMegabyte();
          setTimeout(() => {
            setAnimating("done");
          }, 14000);

          async function handleNotifications() {
            try {
              const permissionResult = await Notification.requestPermission();
              if (permissionResult === "granted") {
              } else {
                console.log("Notification permission denied");
              }
            } catch (error) {
              console.error("Error requesting notification permission:", error);
            }
            setAnimating("done");
          }

          setTimeout(() => {
            handleNotifications();
          }, 14300);
        })
        .catch(() => {
          setTimeout(() => {
            if (offer === "sSpnP3v7") {
              window.location.href = `https://arumo.top/sSpnP3v7?ad_campaign_id=${ad_campaign_id}`;
            } else if (offer === "Qw4YPHMB") {
              window.location.href =
                `https://arumo.top/Qw4YPHMB?ad_campaign_id=${ad_campaign_id}` +
                (creative_id !== null ? `&creative_id=${creative_id}` : "") +
                (source !== null ? `&source=${source}` : "");
            } else if (id === "1481") {
              window.location.href = `https://arumo.top/QqqRGV3C?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}`;
            } else if (id === "1487") {
              window.location.href = `https://arumo.top/CN1NQ9?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1488") {
              window.location.href = `https://arumo.top/s7zqp29Z?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1484") {
              window.location.href = `https://arumo.top/Y5WyCffL?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else if (id === "1483") {
              window.location.href = `https://arumo.top/2yc59j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
            } else {
              window.location.href =
                `https://arumo.top/g8XSqwqq?ad_campaign_id=${ad_campaign_id}` +
                (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                (external_id !== null ? `&external_id=${external_id}` : "") +
                (site_id !== null ? `&site_id=${site_id}` : "");
            }
          }, 400);
        });
    }
    setTimeout(() => {
      document.querySelector(".MuiButton-containedPrimary").click();
    }, 5);
  };

  return (
    <div className="App">
      {supported() && !isInstalled() ? (
        <Main
          handleClick={handleClick}
          animating={animating}
          setAnimating={setAnimating}
          megabyte={megabyte}
          supported={true}
        />
      ) : isInstalled() ? (
        <Installed />
      ) : (
        <>
          <Main
            handleClick={handleClick}
            animating={animating}
            setAnimating={setAnimating}
            megabyte={megabyte}
            supported={false}
          />
        </>
      )}
    </div>
  );
}

export default App;
